<template>
  <!--课程详情-->
  <!--  <div v-title :data-title="courseDetail.courseName">-->
  <div class='wrapper'>
    <div class='class_content'>
      <!--          <div v-if="!haveCata">-->
      <!--            <img-->
      <!--              :src="courseDetail.courseImage"-->
      <!--              width="800"-->
      <!--              style="height: 260px; object-fit: contain"-->
      <!--            />-->
      <!--          </div>-->
      <video
        class='video-js'
        :id="'player' + newTime"
        x5-video-player-fullscreen="true"
        x5-playsinline
        playsinline
        webkit-playsinline
        currentTime
        timeupdate="videoUpdate"
        controlslist="nodownload"
        ended
        controls="false"
      ></video>
    </div>

    <div class='auto clearfix twopart'>
      <van-tabs :animated='false'>
        <van-tab title='课名' >
          <h1 class='courseTitle padding20 font20 fontFamily color999'>{{ courseDetail.courseName }}</h1>
<!--          <div class='introduce font18 padding30'>-->
<!--            <span v-html='courseDetail.detail'></span>-->
<!--          </div>-->
        </van-tab>
        <van-tab ane title='目录' >
          <div class='mulu'>
            <div v-if='isBuy === 0'>
              <div v-for='(cata, cataIndex) in catalogs' :key='cataIndex'>
<!--                 <p class='font22 padding20'>{{cata.cataLogTitle }}</p>-->
                <ul v-if='cata.twoList !== undefined'>
                  <li
                    v-for='(two, index) in cata.twoList'
                    :key='index'
                    @click.stop='play(cataIndex, index)'
                    :class='{
                      active: index === vCurrent && cataIndex === cataCurrent,
                    }'
                  >
<!--                      v-if='isBuy === 0 && two.isFree !== 0'-->
                    <p class='font14' >
                      <img style='width: 20px;height: 20px; margin: -5px 2px 0 15px;'
                           src='../../assets/bf.png' />
                    {{ two.cataLogNo }} {{ two.cataLogTitle }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else>
              <span v-for='(cata, cataIndex) in catalogs' :key='cataIndex'>
<!--                <p class='font22 padding20'>{{ cata.cataLogTitle }}</p>-->
                <ul>
                  <template v-if='cata.twoList !== undefined'>
                  <li
                    v-for='(two, index) in cata.twoList'
                    :key='index'
                    @click.stop='play(cataIndex, index)'
                    :class='{
                      active: index === vCurrent && cataIndex === cataCurrent,
                    }'
                  >
                    <Icon
                      type='outline'
                      v-if='isBuy === 0 && two.isFree !== 0'
                    />
                        <p class='font14'>
                            <img style='width: 20px;height: 20px; margin: -5px 2px 0 15px;'
                              src='../../assets/bf.png' />
                    {{ two.cataLogNo }} {{ two.cataLogTitle }}
                        </p>

                  </li>
                  </template>
                </ul>
              </span>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>

    <Spin fix v-show='isSpinShow'>
      <div class='loader'>
        <Icon type='ios-loading' size='26' class='demo-spin-icon-load'></Icon>
        <div class='font14'>加载中...</div>
      </div>
    </Spin>

    <el-dialog
      title='课后练习'
      fullscreen
      :visible.sync='examData.ifShow'
      v-if='examData.ifShow'
      :append-to-body='true'
    >
      <course-exam
        :examData='examData.detail'
        :examId='catalogs[cataCurrent].twoList[vCurrent].examId'
        @eventUp='examEvent'
      ></course-exam>
<!--      <span slot='footer' class='dialog-footer'> </span>-->
    </el-dialog>
  </div>

</template>

<script src='http://res.wx.qq.com/open/js/jweixin-1.0.0.js'></script>
<script type='text/ecmascript-6'>

import Vue from 'vue'
import { Dialog, Toast } from 'vant'
import TCPlayer from 'tcplayer.js'
import 'tcplayer.js/dist/tcplayer.min.css'
Vue.use(Toast)

import {
  getPsign,
  courseDetail,
  coursetypeWeb,
  getReplysWeb,
  reply,
  collecon,
  addOrder,
  centreInfoWeb,
  findVideo,
  sendPlan,
  shareApp,
  getExamData,
  queryLearnRecord,
  verifyCanPlayVideo
} from '../../http'
import { getStore, setStore } from '../../storage'
import session from '@/utils/session'
import that from 'core-js/internals/is-forced'
import res from 'core-js/internals/is-forced'
export default {
  inject: ['reload'],
  name: 'detail',
  props: {},
  data() {
    return {
      currentTime:0,
      newTime: new Date().getTime(),
      isSpinShow: false,
      isShow: false, // 评论框显示
      nowIndex: -1, // 评论框序号
      textArea: {
        text: '', // 一级评论
        textTwo: '' // 二级评论
      },
      kongjianShare: '', // 空间分享
      weiboShare: '', // 微博分享
      catalogs: '', // 目录
      courseDetail: '', // 课程详情
      isBuy: 2, // 用户是否购买 1是，2否
      isVip: 0, // 用户是否是Vip，0否，不为0是
      isCollecon: 0, // 用户是否搜藏 0否，1是
      replyNum: 0, // 评论总数
      recommends: '', // 推荐视频
      replys: '', // 评论
      courseId: this.$route.query.courseId, // 课程Id
      pageNumber: 1, // 评论页数
      url: window.location.href, // 当前链接
      QRCode: '', // 二维码
      coursetypeFirst: '', // 课程一级类别
      coursetypeSecond: '', // 课程二级类别
      accessKeyId: '', // 云点播四个参数
      securityToken: '',
      accessKeySecret: '',
      vid: '',
      allow: false,
      player: '', // 播放器对象
      cataId:0,
      vCurrent: 0, // 当前二级目录序号
      cataCurrent: 0, // 当前一级目录序号
      haveCata: false, // 是否有课程视频
      recordNum: 0, // 视频浏览量
     // appID: '1309106676',
      appID: '1317579443',
      examData: {
        ifExam: false,
        examId: 0,
        examName: 0,
        timeNode: 0,
        ifShow: false,
        examQstIndex: 0,
        detail: { dtoList: [] }
      },
      learnLog: {
        a:0,
        catalogId: 0,
        effectiveTime: 0,
        videoTime: 0,
        totalLearnTime:0,
      }
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(getStore('userInfo'))
    }
  },
  methods: {

    onLoadedmetadata(e){
      this.checkPrevious(this.cataId)
    },
    videoUpdate(e) {
      console.log("videoUpdate 来咯",e.target.currentTime);
      console.log("videoUpdate 总时长来咯",e.target.duration);
      if (
        // eslint-disable-next-line eqeqeq
        this.examData.ifExam == true &&
        this.catalogs[this.cataCurrent].twoList[this.vCurrent].timeNode > 0 &&
        e.target.currentTime >
        this.catalogs[this.cataCurrent].twoList[this.vCurrent].timeNode &&
        this.userInfo.mobile
      ) {
        console.log('该做题咯')
        this.player.pause()
        this.examData.ifShow = true
      }
        this.learnLog.effectiveTime= parseInt(e.target.currentTime)
        this.learnLog.videoTime= parseInt(e.target.duration)
      },
    getExamData(examId) {
      getExamData({ examId, mobile: this.userInfo.mobile }).then((data) => {
        this.examData.detail = data.data
        Object.assign(this.examData, { ifExam: true, examId })
      })
    },
    examEvent(options) {
      switch (options.type) {
        case 'complete':
          this.examData.ifShow = false
          this.examData.ifExam = false
          break

        default:
          break
      }
    },
    loadStart(e) {
      if (
        Number(this.catalogs[this.cataCurrent].twoList[this.vCurrent].examId) >
        0
      ) {
        this.getExamData(
          this.catalogs[this.cataCurrent].twoList[this.vCurrent].examId
        )
      }
    },
    // 分享获取积分
    courseShare() {
      centreInfoWeb().then((res) => {
        if (res.code === 200) {
          // 用户收藏
          shareApp({ courseId: this.courseId, type: 1 })
            .then((res) => {
            })
            .catch()
        }
      })
    },
    // 回复框显示
    revert(index) {
      if (this.nowIndex === index) {
        this.nowIndex = -1
      } else {
        this.nowIndex = index
      }
    },
    // 课程支付
    apply() {
      if (this.isSpinShow === false) {
        this.isSpinShow = true
        // 查看用户登录状态
        centreInfoWeb()
          .then((res) => {
            if (res.code === 200) {
              // 订单创建成功，跳转支付页面
              this.$router.push({
                name: 'buy',
                query: {
                  courseId: this.courseId
                }
              })
            } else {
              Dialog.alert({
                title: '购买提醒',
                message: res.info,
                duration: 3
              })
            }
          })
      } else {
        // 用户未登录，显示登录框
        Dialog.alert({
          title: '没登陆',
          message: '内容啊'
        }).then(() => {
          Toast('确定了')
        })
      }
    },
    // 默认播放免费视频
    choseIsFree(cata, cataCurrent, vCurrent, nextFree) {
      // nextFree作为是否查询下一个免费课程的标志  true当前免费课程不算，查下一个cou
      let j = cataCurrent
      let i = vCurrent
      let len1 = cata.length
      for (j; j < len1; j++) {
        let len2 = cata[j].twoList.length
        for (i; i < len2; i++) {
          // eslint-disable-next-line eqeqeq
          if (cata[j].twoList[i].isFree == 0) {
            if (!nextFree) {
              // 定义数组，0为vid，1位cataId
              let data = []
              data.push(cata[j].twoList[i].tencentCloudVideoId)
              data.push(cata[j].twoList[i].id)
              this.cataCurrent = j
              this.vCurrent = i
              return data
            }
            nextFree = false
          }
        }
        i = 0
      }
      return null
    },
    // 播放视频切换
    play: function(cataIndex, index) {
      console.log('播放视频切换 切 切 切 ', this.cataId, cataIndex, index, this.learnLog.id)
      this.learnLog.catalogId = this.cataId
      sendPlan(this.learnLog).then((res)=>{
        console.log("保存学习记录",this.learnLog)
        // 选择播放的视频是否允许播放
        if(this.catalogs[cataIndex].twoList[index].isFree === 1){
        this.cataCurrent = cataIndex
        this.vCurrent = index

        this.checkPrevious(this.catalogs[cataIndex].twoList[index].id)
        this.createPlayer(
          this.catalogs[cataIndex].twoList[index].tencentCloudVideoId,
          this.catalogs[cataIndex].twoList[index].id,
          true,
          true
        )
      } else{
        // 判断用户是否登录
        if (this.isBuy === 1) {
          this.checkPrevious(this.catalogs[cataIndex].twoList[index].id)
          // 允许播放，销毁播放器对象，重新创建
          // this.player.destroyed()
          console.log('播放 买了 ', cataIndex, index, this.cataId)
          this.cataCurrent = cataIndex
          this.vCurrent = index
          this.createPlayer(
            this.catalogs[cataIndex].twoList[index].tencentCloudVideoId,
            this.catalogs[cataIndex].twoList[index].id,
            true,
            true
          )
        } else {
          // 没有购买视频
          verifyCanPlayVideo({ catalogId: res.data.catalog[0].twoList[0].id }).then((reslut) => {
            console.log('在这里', res)
            if (reslut.status) {
              this.checkPrevious(res.data.catalog[0].twoList[0].id)
              this.cataCurrent = 0
              this.vCurrent = 0
              this.cataId = res.data.catalog[0].twoList[0].id
              this.createPlayer(
                res.data.catalog[0].twoList[0].tencentCloudVideoId,
                res.data.catalog[0].twoList[0].id,
                false,
                true
              )
            } else {
              // this.cataCurrent = 0
              // this.vCurrent = 0
              // this.createPlayer(
              //   res.data.catalog[0].twoList[0].tencentCloudVideoId,
              //   res.data.catalog[0].twoList[0].id,
              //   false,
              //   false
              // )
              Toast('暂无权限观看')
            }
          })

        }
      }
    })
    },
    // 查看更多评论
    lookMore() {
      this.pageNumber = this.pageNumber + 1
      // 获取更多评论
      getReplysWeb({ courseId: this.courseId, pageNumber: this.pageNumber })
        .then((res) => {
          this.replys = this.replys.concat(res.data.replys)
          this.replyNum = res.data.replyNum
        })
        .catch()
    },
    reply() {
      // 发表一级评论
      // 用户是否登录
      centreInfoWeb()
        .then((res) => {
          if (res.code === 200) {
            // 一级评论发表必须先购买视频
            if (this.isBuy === 1) {
              // 评论
              if (this.textArea.text.trim() !== '') {
                reply({
                  content: this.textArea.text.trim(),
                  targetId: this.courseId,
                  type: 1
                }).then((res) => {
                  if (res.code === 200) {
                    // 评论成功
                    getReplysWeb({
                      courseId: this.courseId,
                      pageNumber: 1
                    })
                      .then((res) => {
                        this.replys = res.data.replys
                        this.replyNum = res.data.replyNum
                        this.textArea.text = ''
                        this.pageNumber = 1
                      })
                      .catch()
                  }
                })
              }
            } else {
              // 未购买视频
              this.$Notice.open({
                title: '这一课你值得拥有',
                desc: '请购买后评论',
                duration: 3
              })
            }
          } else {
            // 未登录
            this.$refs.loginD.showModal()
          }
        })
        .catch()
    },
    replyTwo(id) {
      // 发表二级评论
      if (this.textArea.textTwo.trim() !== '') {
        // 发表评论必须登录
        centreInfoWeb()
          .then((res) => {
            if (res.code === 200) {
              // 发表评论
              reply({
                content: this.textArea.textTwo.trim(),
                targetId: id,
                type: 2
              })
                .then((res) => {
                  if (res.code === 200) {
                    // 评论成功
                    getReplysWeb({
                      courseId: this.courseId,
                      pageNumber: 1
                    })
                      .then((res) => {
                        this.replys = res.data.replys
                        this.replyNum = res.data.replyNum
                        this.textArea.textTwo = ''
                        this.pageNumber = 1
                        this.nowIndex = -1
                      })
                      .catch()
                  }
                })
                .catch()
            } else {
              // 用户未登录
              this.$refs.loginD.showModal()
            }
          })
          .catch()
      }
    },
    // 用户点赞
    dianZan: function(replyId, index) {
      collecon({ targetId: replyId, type: 3, optType: 2 })
        .then((res) => {
          if (this.replys[index].isThumbs === 1) {
            this.replys[index].isThumbs = 0
            this.replys[index].thumbs = this.replys[index].thumbs - 1
          } else {
            this.replys[index].isThumbs = 1
            this.replys[index].thumbs = this.replys[index].thumbs + 1
          }
        })
        .catch()
    },
    // 用户收藏
    shouCang: function() {
      centreInfoWeb()
        .then((res) => {
          if (res.code === 200) {
            collecon({ targetId: this.courseId, type: '1', optType: 1 })
              .then((res) => {
                if (res.data === 1) {
                  this.isCollecon = 1
                }
                if (res.data === 3) {
                  this.isCollecon = 0
                }
              })
              .catch()
          } else {
            // 用户未登录
            this.$refs.loginD.showModal()
          }
        })
        .catch()
    },
    // 推荐课程切换
    detail(index) {
      let cour = this.recommends[index]
      this.$router.push({
        name: 'coursedetailxcx',
        query: { courseId: cour.courseId }
      })
      this.reload()
      this.$route.meta.title = this.courseDetail.courseName
    },
    // 创建播放器对象
    createPlayer(vid, cataId, isLogin, isPlay) {
      console.log('创建播放器对象', vid, cataId, isLogin, isPlay)
      //获取播放签名
      getPsign({ appId:this.appID,fileId:vid}).then((res) => {
       if (res.status){
         let psign= res.data
         let TCPlayMaker = (vid, cataId, isLogin, isPlay) => {
           if (this.player) {
             this.cataId=cataId
             console.log('1111111vid',vid,cataId,isLogin,isPlay)
             this.player.loadVideoByID({
               fileID: vid,
               appID:  this.appID,
               psign: psign
             })
             console.log('222222',vid)
             return this.player
           }
           let player = TCPlayer('player' + this.newTime, {
             fileID: vid,
             appID: this.appID,
             psign: psign
           })
           console.log('33333player', vid)
           setTimeout(() => {
             player.on('play', () => {
               if (!this.$route.query.token) {
                 console.log('555', player)
                 Toast.fail('未登录')
                 player.pause()
               } else {
                 console.log('666', player)
                 if (!isPlay) {
                   console.log('777', player)
                   player.pause()
                   Dialog({
                     title: '提示',
                     message: '暂无课程观看权限',
                     showConfirmButton:false
                   })
                 } else {
                   console.log('888', player)
                 }
               }
                 this.learnLog.videoTime =parseInt( player.duration());
                 console.log("来了吗111", this.learnLog.videoTime)
             }
             )
             player.on('pause', ()=> {
               // 做一些处理
               console.log("pause暂停了");
                this.sendLearnTime();
             });
             player.on('timeupdate', ()=> {
               // 做一些处理
               let e = parseInt(player.currentTime());
               console.log("哇哦噢噢噢噢", e- this.learnLog.effectiveTime);
               if (this.learnLog.effectiveTime - e == this.learnLog.videoTime ){
                 //重播
                 this.player.currentTime(0);
                 this.learnLog.effectiveTime ==0;
                 console.log("重播");
               }
               else if (e - this.learnLog.effectiveTime > 1 || e - this.learnLog.effectiveTime < 0) {
                this.player.currentTime(this.learnLog.effectiveTime);
                 console.log("快进了");
               }else{
                 this.learnLog.effectiveTime = e;
                 console.log("当前时间",this.learnLog.effectiveTime);
               }
            this.learnLog.effectiveTime = parseInt(player.currentTime())
             });
            // player.on('ended', ended);
             player.on('ended', ()=> {
               // 暂停播放
               console.log("播放结束了");
               this.player.pause();
             });
           })
           return player
         }

         let ended = () => {
           // 注：cata是为了保存cataId，这里调用了centreInfoWeb用来判断用户是否登录，随后更新学习计划。
           // 因为接口请求的慢，播放器对象已经创建导致cataId发生改变，更新学习计划使用的cataId变成下一个cataId，故定义变量保存cataId
           this.learnLog.catalogId = cataId
          console.log("aaaaaa烦",cataId)
           sendPlan(this.learnLog )
           player.pause()
           console.log("aaaaaa暂停了",cataId)
             // centreInfoWeb()
             //   .then((res) => {
             //     if (res.code === 200) {
             //       sendPlan({ catalogId: cata })
             //         .then((res) => {
             //         })
             //         .catch()
             //     }
             //   })
             .catch()
           // 播放完成，自动播放下一个
           // 用户已经购买直接播放下一个视频
           // if (this.isBuy === 1) {
           //   // 二级目录还有课程
           //   if (
           //     this.vCurrent + 1 <
           //     this.catalogs[this.cataCurrent].twoList.length
           //   ) {
           //     this.vCurrent++
           //     vid =
           //       this.catalogs[this.cataCurrent].twoList[this.vCurrent]
           //         .tencentCloudVideoId
           //     cataId = this.catalogs[this.cataCurrent].twoList[this.vCurrent].id
           //     this.player = TCPlayMaker(vid, cataId, isLogin, isPlay)
           //     // 二级目录没有课程切换一级目录
           //     // 仍有下一个一级目录且该一级目录有二级目录
           //   } else if (
           //     this.cataCurrent + 1 < this.catalogs.length &&
           //     this.catalogs[this.cataCurrent].twoList.length > 0
           //   ) {
           //     this.cataCurrent++
           //     this.vCurrent = 0
           //     vid =
           //       this.catalogs[this.cataCurrent].twoList[this.vCurrent]
           //         .tencentCloudVideoId
           //     cataId = this.catalogs[this.cataCurrent].twoList[this.vCurrent].id
           //     this.player.dispose()
           //     this.player = TCPlayMaker(vid, cataId, isLogin, isPlay)
           //   } // 还存在一种情况，没有一级目录没有二级目录，不在创建播放器对象，故不适用else
           // } else {
           //   // 用户没有购买，播放免费视频
           //   console.log(this)
           //   let data = this.choseIsFree(
           //     this.catalogs,
           //     this.cataCurrent,
           //     this.vCurrent,
           //     true
           //   )
           //   //看看有没有在企业导入免费看课名单里
           //
           //   // data不为空说明还存在免费视频，继续播放
           //   if (data !== null) {
           //     vid = data[0]
           //     cataId = data[1]
           //     this.player = TCPlayMaker(vid, cataId, isLogin, isPlay)
           //   }else{
           //     verifyCanPlayVideo({catalogId: res.data.catalog[0].twoList[0].id}).then((reslut) => {
           //       if (reslut.status){
           //         this.cataCurrent = 0
           //         this.vCurrent = 0
           //         this.cataId=res.data.catalog[0].twoList[0].id
           //         this.createPlayer(
           //           res.data.catalog[0].twoList[0].tencentCloudVideoId,
           //           res.data.catalog[0].twoList[0].id,
           //           false,
           //           true
           //         )
           //       }
           //       else{
           //         this.cataCurrent = 0
           //         this.vCurrent = 0
           //         this.cataId=  res.data.catalog[0].twoList[0].id
           //         this.createPlayer(
           //           res.data.catalog[0].twoList[0].tencentCloudVideoId,
           //           res.data.catalog[0].twoList[0].id,
           //           false,
           //           false
           //         )
           //       }
           //     })
           //   }
           // }
         }
         this.player = TCPlayMaker(vid, cataId, isLogin, isPlay)
       }
      })

    },
    checkPrevious(c) {
      console.log('检查上次学习记录',c)
      this.cataId = c
      let lastEffectiveTime = null
      queryLearnRecord({ catalogId: c }).then((res) => {
        if (res.code === 200) {
          console.log('查询res.data', res.data)
          if (res.data.videoTime !=0 ) {
            this.learnLog.id = res.data.id
            console.log('查询res.data 设置id', this.learnLog.id)
            this.learnLog.videoTime = res.data.videoTime ?? 0
            lastEffectiveTime = res.data.effectiveTime
            console.log('lastEffectiveTime', res.data.effectiveTime)
            if (res.data.effectiveTime>0) {
              Dialog.confirm({
                title: '提示',
                message: '请问是否继续上次学习的进度',
                cancelButtonText: '取消'
              }).then(() => {
                Toast('已跳转至上次学习进度')
                this.learnLog.effectiveTime = res.data.effectiveTime
                //跳转到固定位置
                this.player.currentTime(lastEffectiveTime)
                this.player.play()
              }).catch(() => {
                Toast('取消了')
                this.player.play()
              })
            }
          }
        }
      })
    },
    sendLearnTime(){
      this.learnLog.catalogId = this.cataId
      sendPlan(this.learnLog)
      console.log("sendLearnTime 来咯",this.learnLog)
    },
    videoLoaded(e) {
      console.log("videoLoaded进来了",e);
      this.learnLog.effectiveTime = parseInt(e.detail.detail.duration)
    },
    // 首次播放
    initPlay() {
      // 获取课程类型
      coursetypeWeb({ courseId: this.courseId })
        .then((res) => {
          this.coursetypeFirst = res.data.coursetypeFirst
          this.coursetypeSecond = res.data.coursetypeSecond
        })
        .catch()
      // 获取课程详细信息（播放器需要的4个参数）
      courseDetail({ courseId: this.courseId, pageNumber: 1, url: this.url })
        .then((res) => {
          this.isSpinShow = false
          // 浏览器标题配置
          this.catalogs = res.data.catalog
          this.recordNum = res.data.recordNum
          this.isVip = res.data.isVip
          this.courseDetail = res.data.courseDetail
          this.isBuy = res.data.isBuy
          this.isCollecon = res.data.isCollecon
          this.recommends = res.data.recommends
          this.replys = res.data.replys
          this.replyNum = res.data.replyNum
          this.QRCode = 'data:image/png;base64,' + res.data.QRCode
          this.accessKeyId = res.data.akid
          this.securityToken = res.data.token
          this.accessKeySecret = res.data.akSecret
          if (res.data.catalog.length > 0) {
            if (res.data.catalog[0].twoList.length > 0) {
              this.haveCata = true
              // 用户是否购买
              if (res.data.isBuy > 0) {
                // this.checkPrevious(res.data.catalog[0].twoList[0].id).then(() =>{
                //   // 用户已购买，购买之后播放第一个视频
                //   let vid = res.data.catalog[0].twoList[0].tencentCloudVideoId
                //   let cataId = res.data.catalog[0].twoList[0].id
                //   this.cataId = cataId
                //   this.cataCurrent = 0
                //   this.vCurrent = 0
                //   //this.createPlayer(vid, cataId, false, true);
                //   this.createPlayer(
                //     res.data.catalog[0].twoList[0].tencentCloudVideoId,
                //     cataId,
                //     true,
                //     true
                //   )
                //   console.log('买了大的')
                // })
                let cataId = res.data.catalog[0].twoList[0].id
                let vid = res.data.catalog[0].twoList[0].tencentCloudVideoId
                this.checkPrevious(cataId).then(res=>{
                  // 用户已购买，购买之后播放第一个视频
                  this.cataId = cataId
                  this.cataCurrent = 0
                  this.vCurrent = 0
                  this.createPlayer(vid, cataId, true, true);
                })
              } else {
                // 目录里是否有免费课程
                // 课程是否免费，免费课程用户不必登录
                console.log('没买')
                let data = this.choseIsFree(
                  this.catalogs,
                  this.cataCurrent,
                  this.vCurrent,
                  false
                )
                if (data !== null) {
                  this.checkPrevious(data[1]).then(res=>{
                    console.log('播免费的')
                    console.log(data)
                    this.createPlayer(data[0], data[1], false, true)
                    //当前视频id
                    this.cataId = data[1]
                  })
                } else {
                  console.log('没免费的')
                  //看看有没有在企业名单内
                  verifyCanPlayVideo({catalogId: res.data.catalog[0].twoList[0].id}).then((reslut) => {
                    if (reslut.status){
                      this.checkPrevious(res.data.catalog[0].twoList[0].id)
                      this.cataCurrent = 0
                      this.vCurrent = 0
                      this.cataId =   res.data.catalog[0].twoList[0].id
                      this.createPlayer(
                        res.data.catalog[0].twoList[0].tencentCloudVideoId,
                        res.data.catalog[0].twoList[0].id,
                        false,
                        true
                      )

                    }
                    else{
                      this.cataCurrent = 0
                      this.vCurrent = 0
                      this.createPlayer(
                        res.data.catalog[0].twoList[0].tencentCloudVideoId,
                        res.data.catalog[0].twoList[0].id,
                        false,
                        false
                      )
                    }
                  })
                }
              }
            } else {
              this.haveCata = false
            }
          } else {
            this.haveCata = false
          }
          this.$route.meta.title = this.courseDetail.courseName
          // 分享
          this.kongjianShare =
            'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?' +
            'title=' +
            encodeURIComponent(this.courseDetail.courseName) +
            '&url=' +
            encodeURIComponent(res.data.urlShare) +
            '&summary=' +
            encodeURIComponent(res.data.content.replace(/&nbsp;/g, '')) +
            '"'
          this.weiboShare =
            'http://service.weibo.com/share/share.php?title=【' +
            encodeURIComponent(this.courseDetail.courseName) +
            '】' +
            encodeURIComponent(res.data.content.replace(/&nbsp;/g, '')) +
            '&url=' +
            encodeURIComponent(res.data.urlShare) +
            '&appkey=411882175' +
            '"'
        })
        .catch()
    },
    setStore() {
      setStore('token', this.$route.query.token)
    }
  },
  mounted: function() {
    console.log('jinlaile ')
    session.set('token', this.$route.query.token)
    this.setStore()
    console.log('settoken', this.$route.query.token)
    if (this.$route.query.token != null) {
      if (this.isSpinShow === false) {
        this.isSpinShow = true
      }
        this.initPlay()
    }
    // 配置
    // this.$wx.config({
    //   debug: false,
    //   appId: 'wx996b8c70b2ecf64a',
    //   timestamp: '',
    //   nonceStr: '',
    //   signature: '',
    //   jsApiList: []
    // })
    // // 上面配置错误也无所谓的，即使配置失败，也会走 ready 函数
    // // 主要目的就是为了走 ready 函数
    // this.$wx.ready(() => {
    //   console.log('成功')
    //   // 取得播放器对象，调用播放事件
    //   this.initPlay()
    // })
    let ran = navigator.userAgent
    let isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
    let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    console.log("isAndroid",isAndroid ,"isIOS",isIOS)
    // if (ran() === 'IOS') {
    //   console.log("IOS")
    //   window.addEventListener('pagehide', e => this.unloadHandler(e))
    // } else {
    //   console.log("IOS else")
    //   window.addEventListener('unload', e => this.unloadHandler(e))
    // }
  },
  beforeDestroy:function() {
    console.log(" beforeDestroy进来了",this.player)
    this.player = null
    if (this.learnLog.catalogId!=0 && this.learnLog.videoTime!=0){
      this.sendLearnTime();
    }
  },
  destroyed:function() {
    console.log("bb destroyed进来了")
    if (this.learnLog.catalogId!=0 && this.learnLog.videoTime!=0){
      this.sendLearnTime();
    }
    //
    // let ran = navigator.userAgent
    // let isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
    // let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    // console.log("destroyed isAndroid",isAndroid ,"isIOS",isIOS)
    // if (ran === 'IOS') {
    //   console.log("destroyed IOS")
    //   window.removeEventListener('pagehide', e => this.unloadHandler(e))
    // } else {
    //   console.log("destroyed IOS else")
    //   window.removeEventListener('unload', e => this.unloadHandler(e))
    // }
  },
  components: {},
  watch:{

  },
}
// varvideo=document.getElementById('video');
//
// video.addEventListener('error',function(){
//   var error=video.error;//返回一个错误对象
//
//   switch(error.code){  //根据错误代码执行不同的错误状态
//
//     case 1:alert('读取过程中被用户中止');
//
//       break;
//
//     case 2:alert('下载时发生错误');
//
//       break;
//
//     case 2:alert('解码时发生错误');
//
//       break;
//
//     case 2:alert('媒体不可用或者不支持音视频');
//
//       break;
//
//   }
//
// },false);

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='less'>

/* 旋转效果 */
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.video-js{
width: 100%;
  height:100%;
  object-fit: fill;
  width: 100%;
  max-width: 100%;
  padding-top: 56.25%;
}
/* 假如内容过长，一屏放不下，滚动条下拉覆盖不全 */
.ivu-spin-fix {
  position: fixed;
}
.class_content {
  width: 100%;
  height: 210px;
  object-fit: fill;
}
.wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  .names_title {
    background-color: #fff;
    color: #000;
    font-size: 22px;
    padding: 30px;
    text-align: left;
    margin-top: 20px;

    p:nth-child(2) {
      color: #ff3300;
      margin-top: 5px;
    }

    button {
      margin-left: 10px;
      background-color: #2944c2;
      border-color: #2944c2;
      height: 41px;
      width: 159px;
      font-size: 16px;
      color: #fff;
      margin-top: 15px;
    }
  }

  .auto.twopart.clearfix {
    background-color: #fff;
    width: 100%;
    height: 100%;
    .courseTitle {
      margin:20px 0;
    }

    .padding30 {
      padding: 0 30px;
    }

   .padding20 {
      padding: 5px 20px;
    }

    .font24 {
      font-size: 24px;
    }

    .font20 {
      font-size: 20px;
    }
    .font22 {
      font-size: 22px;
    }

    .font18 {
      font-size: 18px;
    }
    .font14 {
      font-size: 14px;
    }
    .color999{
      color:#999;
    }
    .font16 {
      font-size: 16px;
    }
    .introduce {
      color: #666;
    }
    ul {
      // color: #fff;
      padding-left: 15px;
      height: 100%;
      margin-top: 10px;
    }
    .active {
      color: #66CCCC;
     // background-color: #99CCCC;
    }

    li {
      padding: 8px 0;
    //  margin-bottom: 10px;
      cursor: pointer;
      color: #999;
      &:hover {
        color: #66CCCC;
       // background-color: #66CCCC;
      }

      .color333 {
        color: #333;
      }

      p.pull-left {
        position: relative;

        span {
          background-color: #ff9900;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          color: #fff;
          font-size: 12px;
          text-align: center;
          padding: 0px 5px;
          position: absolute;
          bottom: 15px;
          left: 0;
        }
      }

      .wid170 {
        width: 171px;
      }

      div.pull-left {
        padding: 0;
        font-size: 14px;
        color: #333;
        margin-left: 20px;
        height: auto;

        p:first-child {
          margin: 3px 0;
        }

        p:last-child {
          font-size: 14px;
          img {
            vertical-align: middle;
            margin-right: 10px;
          }

          span {
            margin-left: 20px;
          }
        }
      }

      div.curse {
        position: relative;
        margin-left: 0;

        .bg {
          width: 151px;
          height: 81px;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.39);
        }

        .play {
          position: absolute;
          z-index: 9;
          top: 50%;
          left: 50%;
          margin-left: -13px;
          margin-top: -13px;
        }

        .vip {
          padding: 0;
          //background: url("../../assets/vip.png") no-repeat;
          width: 24px;
          height: 36px;
          background-size: 100% 100%;
          font-size: 12px;
          color: #fff;
          line-height: 30px;
          text-align: center;
          position: absolute;
          right: 0;
          top: 0;
        }

        span {
          background-color: #ff9900;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          color: #fff;
          font-size: 12px;
          text-align: center;
          padding: 3px 5px;
          position: absolute;
          bottom: 10px;
          left: 0;
        }
      }

      div.shipin p:last-child {
        font-size: 14px;
        color: #999;

        img {
          vertical-align: middle;
          margin: 0 5px 0 20px;
        }
      }
    }
  }
  //::v-deep .van-tab--active {
  //  font-size: 22px;
  //}
  //微信图片分享

  .weixinshare {
    position: relative;

    .share_img {
      position: absolute;
      top: 23px;
      left: 0;
      background-color: #fff;
      padding: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      z-index: 2;
      display: none;
    }

    &:hover .share_img {
      display: block;
      transition: all 0.3s;
    }
  }

  img {
    vertical-align: middle;
  }

  > img:nth-of-type(2) {
    margin-left: 30px;
  }
}
.mulu{
  margin:3px 0;
}

/deep/ .van-tabs__line {
  background-color: #54C3CC;
  width: 50%;
}
/deep/ .van-tab   {
  font-size: 16px;
  padding: 3px 0;
}
</style>

